<template>
	<div class="row">
      <div id="search-nav" class="col-12 d-block btn-group dropup">
         <button type="button" class="btn btn-primary dropdown-toggle d-block d-lg-none" data-bs-toggle="dropdown" data-bs-auto-close="false" aria-expanded="false">
            <i class="far fa-search font-17 mx-auto"></i><i class="fal fa-times font-17 mx-auto"></i>
         </button>
         <div class="card mb-2 dropdown-menu dropdown-menu-left">
            <div class="card-body pb-0">
               <div class="row">
                  <div class="col-md mb-3">
							<label><i class="far fa-user color-theme font-11 me-1"></i> Nome/CPF/RG</label>
                     <input type="text" class="form-control" v-model="pesquisa.valor" maxlength="200" v-focus @keyup.enter="buscarContas" placeholder="Busque por nome da conta, CPF ou RG" />
                  </div>
						<div class="col-md-4 col-xl-2 px-0 mb-3 align-self-end">
							<div class="row">
                        <div class="col">
									<button type="button" class="btn btn-primary w-100" @click="buscarContas"><i class="fas fa-search font-13"></i></button>
                        </div>
                        <div class="col-8">
									<button type="button" class="btn btn-primary w-100" @click="abrirModal(null)">
										<i class="far fa-plus font-13 me-1"></i> Adicionar
									</button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <div class="col-12" v-if="pesquisa.retorno.resultado.length > 0">
			<div class="card mb-0">
				<div class="card-body p-12">
					<div class="row">
						<div class="col mb-2 align-self-center">
							<div class="limitador-1 mb-1"><i class="fas fa-angle-right color-theme me-1"></i> {{ pesquisa.retorno.resultado.length == 100 ? 'Limitado a ': '' }} {{ pesquisa.retorno.resultado.length }} resultado(s)</div>
						</div>

						<conta v-for="(usuario, index) in pesquisa.retorno.resultado" :key="index" :usuario="usuario" :index="index" @info="abrirInfo($event)" />
					</div>
				</div>
			</div>
      </div>
		
      <!-- modalConta -->
      <div class="modal fade" id="modalConta" tabindex="-1" aria-labelledby="modalContaLabel" aria-hidden="true">
         <div class="modal-dialog modal-xxl h-100 modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content h-100">
               <div class="modal-header">
                  <h5 class="modal-title" id="modalContaLabel">{{ modal.id == null ? 'Adicionar' : 'Editar' }} conta {{ modal.id == null ? '' : ('(ID '+ modal.id +')') }}</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body">
						<div class="card mb-2">
							<div class="card-body p-12">
								<ul class="nav nav-pills" id="pills-tab" role="tablist">
									<li class="nav-item col" role="presentation">
										<button class="nav-link m-0 active" id="pills-conta-tab" data-bs-toggle="pill" data-bs-target="#pills-conta" type="button" role="tab" aria-controls="pills-conta" aria-selected="true">
											<i class="far fa-home font-12"></i><span class="ms-1 d-none d-sm-inline"> Conta</span>
										</button>
									</li>
									<li class="nav-item col" role="presentation">
										<button class="nav-link m-0" id="pills-usuario-tab" data-bs-toggle="pill" data-bs-target="#pills-usuario" type="button" role="tab" aria-controls="pills-usuario" aria-selected="false">
											<i class="far fa-user font-12"></i><span class="ms-1 d-none d-sm-inline"> Usuário</span>
										</button>
									</li>
								</ul>
							</div>
						</div>

						<div class="tab-content" id="pills-tabContent">
							<div class="tab-pane fade show active" id="pills-conta" role="tabpanel" aria-labelledby="pills-conta-tab">
								<div class="card">
									<div class="card-body p-12">
										<div class="row">
											<div class="col-6 col-lg-4 mb-3">
												<label><i class="far fa-stream color-theme font-11 me-1"></i> Tipo empresa *</label>
												<v-select name="Desktop" :options="['Lucro real', 'Simples nacional']" v-model="modal.tipoEmpresa" :clearable="false" :searchable="false" placeholder="Selecione uma opção" />
												<select name="Mobile" class="form-control" v-model="modal.tipoEmpresa">
													<option v-for="(option, index) in ['Lucro real', 'Simples nacional']" :key="index" :value="option">{{ option }}</option>
												</select>
											</div>
											<div class="col-6 col-lg-4 mb-3">
												<label><i class="far fa-wifi-slash color-theme font-11 me-1"></i> Caixas Offline *</label>
												<input type="text" class="form-control" v-model="modal.qtdPdvsOffline" maxlength="200" />
											</div>
											<div class="col-6 col-lg-4 mb-3">
												<label><i class="far fa-wifi color-theme font-11 me-1"></i> Caixas Online *</label>
												<input type="text" class="form-control" v-model="modal.qtdPdvsOnline" maxlength="200" />
											</div>
											<div class="col-6 col-lg-4 mb-3">
												<label><i class="far fa-store color-theme font-11 me-1"></i> Lojas *</label>
												<input type="text" class="form-control" v-model="modal.qtdLojas" maxlength="200" />
											</div>
											<div class="col-6 col-lg-4 mb-3">
												<label><i class="far fa-users color-theme font-11 me-1"></i> Usuários *</label>
												<input type="text" class="form-control" v-model="modal.qtdUsuarios" maxlength="200" />
											</div>
											<div class="col-6 col-lg-4 mb-3">
												<label><i class="far fa-money-bill-wave-alt color-theme font-11 me-1"></i> Valor Crédito *</label>
												<input type="text" class="form-control" v-model="modal.credito" />
											</div>
											<div class="col-6 col-lg-4 mb-3">
												<label><i class="far fa-calendar-day color-theme font-11 me-1"></i> Dias vencimento *</label>
												<input type="text" class="form-control" v-model="modal.diasVencimento" />
											</div>
											<div class="col-6 col-lg-4 mb-3">
												<label><i class="far fa-calendar color-theme font-11 me-1"></i> Limite teste</label>
												<input type="text" class="form-control" v-model="modal.dataLimiteTeste" v-mask="'##/##/####'" />
											</div>
											<div class="col-6 col-lg-4 mb-3">
												<label><i class="far fa-dollar-sign color-theme font-11 me-1"></i> Valor fatura *</label>
												<input type="text" class="form-control" v-model="modal.valorFatura" />
											</div>
										</div>
									</div>
								</div>
							</div>

							<div class="tab-pane fade" id="pills-usuario" role="tabpanel" aria-labelledby="pills-usuario-tab">
								<div class="accordion" id="accordionExample">
									<div class="accordion-item">
										<h2 class="accordion-header" id="headingOne">
											<button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
												<i class="fal fa-home"></i> Informações básicas
											</button>
										</h2>
										<div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
											<div class="accordion-body">
												<div class="row">
													<div class="col-sm-6 mb-3">
														<label><i class="far fa-user color-theme font-12 me-1"></i> Nome *</label>
														<input type="text" class="form-control" v-model="modal.nomeCompleto" maxlength="200" />
													</div>
													<div class="col-sm-6 mb-3">
														<label><i class="far fa-envelope color-theme font-11 me-1"></i> Email *</label>
														<input type="text" class="form-control" v-model="modal.email" maxlength="200" />
													</div>
													<div class="col-sm-6 mb-3">
														<label><i class="far fa-address-card color-theme font-11 me-1"></i> CPF *</label>
														<input type="text" class="form-control" v-model="modal.cpf" v-mask="['###.###.###-##', '##.###.###/####-##']" />
													</div>
													<div class="col-sm-6 mb-3">
														<label><i class="far fa-address-card color-theme font-11 me-1"></i> RG</label>
														<input type="text" class="form-control" v-model="modal.rg" maxlength="200" />
													</div>
													<div class="col-sm-6 mb-3 mb-sm-0">
														<label><i class="far fa-calendar color-theme font-11 me-1"></i> Data nascimento</label>
														<input type="text" class="form-control" v-model="modal.dataNascimento" v-mask="'##/##/####'" />
													</div>
													<div class="col-sm-6">
														<label><i class="far fa-lock color-theme font-11 me-1"></i> Senha de acesso</label>
														<input type="text" class="form-control key" v-model="modal.senha" maxlength="200" />
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="accordion-item">
										<h2 class="accordion-header" id="headingTwo">
											<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
												<i class="fal fa-map-marker-alt me-3"></i>Endereço
											</button>
										</h2>
										<div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
											<div class="accordion-body">
												<div class="row">
													<div class="col-sm-4 mb-3">
														<label><i class="far fa-location-arrow color-theme font-11 me-1"></i> CEP</label>
														<div class="input-group">
															<input type="text" class="form-control" v-model="modal.endereco.cep" v-mask="'##########'" @keyup.enter="buscarCEP" />
															<a href="javascript:;" class="input-group-text font-13" @click="buscarCEP">
																<i class="fas fa-search"></i>
															</a>
														</div>
													</div>
													<div class="col-sm-8 mb-3">
														<label><i class="far fa-home color-theme font-11 me-1"></i> Rua</label>
														<input type="text" class="form-control" v-model="modal.endereco.rua" maxlength="200" />
													</div>
													<div class="col-sm-4 mb-3">
														<label><i class="far fa-hashtag color-theme font-11 me-1"></i> Número</label>
														<input type="text" class="form-control" v-model="modal.endereco.numero" maxlength="200" />
													</div>
													<div class="col-sm-8 mb-3">
														<label><i class="far fa-road color-theme font-11 me-1"></i> Bairro</label>
														<input type="text" class="form-control" v-model="modal.endereco.bairro" maxlength="200" />
													</div>
													<div class="col-sm-6 mb-3">
														<label><i class="far fa-map-signs color-theme font-11 me-1"></i> Cidade</label>
														<input type="text" class="form-control" v-model="modal.endereco.cidade" maxlength="200" />
													</div>
													<div class="col-sm-6 mb-3">
														<label><i class="far fa-map-marker-alt color-theme font-11 me-1"></i> Estado</label>
														<v-select name="Desktop" :options="estados" v-model="modal.endereco.estado" :clearable="false" :searchable="false" placeholder="Selecione uma opção" />
														<select name="Mobile" class="form-control" v-model="modal.endereco.estado">
															<option v-for="(option, index) in estados" :key="index" :value="option">{{ option }}</option>
														</select>
													</div>
													<div class="col-sm-4 mb-3 mb-sm-0">
														<label><i class="far fa-map-signs color-theme font-11 me-1"></i> Cód. Município</label>
														<input type="text" class="form-control" v-model="modal.endereco.codMunicipio" maxlength="200" />
													</div>
													<div class="col-sm-8">
														<label><i class="far fa-tag color-theme font-11 me-1"></i> Complemento</label>
														<input type="text" class="form-control" v-model="modal.endereco.complemento" maxlength="200" />
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="accordion-item">
										<h2 class="accordion-header" id="headingThree">
											<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
												<i class="fal fa-phone-alt"></i> Telefone
											</button>
										</h2>
										<div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
											<div class="accordion-body">
												<div class="row">
													<div class="col-sm-3 mb-3">
														<label><i class="far fa-hashtag color-theme font-11 me-1"></i> DDD</label>
														<input type="text" class="form-control" v-model="modal.telefone.ddd" v-mask="['(##)', '(###)']" />
													</div>
													<div class="col-sm-5 mb-3">
														<label><i class="far fa-hashtag color-theme font-11 me-1"></i> Número</label>
														<input type="text" class="form-control" v-model="modal.telefone.telefone" v-mask="['####-####', '#####-####']" />
													</div>
													<div class="col-sm-4 mb-3 mb-sm-0">
														<label><i class="far fa-tag color-theme font-11 me-1"></i> Tipo</label>
														<v-select name="Desktop" :options="['Fixo', 'Celular']" v-model="modal.telefone.tipo" :clearable="false" :searchable="false" placeholder="Selecione uma opção" />
														<select name="Mobile" class="form-control" v-model="modal.telefone.tipo">
															<option v-for="(option, index) in ['Fixo', 'Celular']" :key="index" :value="option">{{ option }}</option>
														</select>
													</div>
													<div class="col-12">
														<label><i class="far fa-user-alt color-theme font-11 me-1"></i> Nome contato</label>
														<input type="text" class="form-control" v-model="modal.telefone.nomeContato" maxlength="200" />
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
               </div>
               <div class="modal-footer">
						<button type="button" class="btn btn-primary" @click="salvarConta">Salvar</button>
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import $ from 'jquery'
import router from '@/router'
import { mapState } from 'vuex'
import conta from '@/components/contas/Conta.vue'

export default {
	name: 'Contas',
	data: function () {
		return {
			pesquisa: {'valor': '', 'retorno': {'nome': null, 'resultado': []}},
			modal: {
				'id': null, 'idConta': null, 'cpf': null, 'rg': null, 'dataNascimento': null, 'email': null, 'nomeCompleto': null, 'senha': null, 
				'endereco': {'id': null, 'bairro': '', 'cep': '', 'cidade': '', 'codMunicipio': '', 'complemento': '', 'estado': '', 'numero': '', 'pais': '', 'rua': ''}, 
				'telefone': {'id': null, 'ddd': '', 'nomeContato': '', 'telefone': '', 'tipo': 'Fixo'}, 'qtdPdvsOnline': 1, 'qtdPdvsOffline': 0, 'qtdLojas': 1, 'qtdUsuarios': 1, 'tipoEmpresa': 'Simples nacional', 'valorFatura': '0.00', 'credito': '0.00', 
				'diasVencimento': 10, 'dataLimiteTeste': null
			}
		}
	},
	computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario,
         urlRest: state => state.urlRest,
         estados: state => state.estados,
			tabs: state => state.tabs
      })
   },
	watch: {
		$data: {
			handler: function(value) {
				this.$store.dispatch('attTabData', JSON.parse(JSON.stringify({'id': this.$route.params.idTab, 'data': value})))
			},
			deep: true
		},
		'$route.params.idTab': function (value) {
         Object.assign(this.$data, this.tabs[value].data)
      },
		'modal.credito' : function (newVal) {
			this.$store.dispatch('formatValue', {'valor': newVal, 'digitos': 2}).then((valor) => {
				this.modal.credito = valor
			})
		},
		'modal.valorFatura' : function (newVal) {
			this.$store.dispatch('formatValue', {'valor': newVal, 'digitos': 2}).then((valor) => {
				this.modal.valorFatura = valor
			})
		}
	},
	components: {
		conta
	},
	methods: {
		abrirModal : function (conta) {
			this.$store.dispatch('alternarTelaCarregamento', true)
			Object.assign(this.$data.modal, this.$options.data.apply(this).modal)
			
			this.$axios({
				method: 'get',
				url: this.urlRest +'configuracoesAdmin/getUsuario',
				params: {
					email: conta == null ? null : conta.usuarios[0].emailUsuario
				}
			}).then(response => {
				$('#modalConta').modal('show')
				let contaObj = JSON.parse(JSON.stringify(conta))

				this.modal = {
					'qtdPdvsOnline': (contaObj.qtdPdvsOnline == null ? 1 : contaObj.qtdPdvsOnline), 
					'qtdPdvsOffline': (contaObj.qtdPdvsOffline == null ? 0 : contaObj.qtdPdvsOffline), 
					'qtdLojas': (contaObj.qtdLojas == null ? 1 : contaObj.qtdLojas), 
					'qtdUsuarios': (contaObj.qtdUsuarios == null ? 1 : contaObj.qtdUsuarios), 
					'tipoEmpresa': (contaObj.tipoEmpresa == null ? 'Simples nacional' : contaObj.tipoEmpresa), 
					'valorFatura': (contaObj.valorFatura == null ? '0.00' : parseFloat(contaObj.valorFatura).toFixed(2)), 
					'credito': (contaObj.credito == null ? '0.00' : parseFloat(contaObj.credito).toFixed(2)), 
					'diasVencimento': (contaObj.diasVencimento == null ? 10 : contaObj.diasVencimento), 
					'dataLimiteTeste': (contaObj.dataLimiteTeste == null ? null : String(contaObj.dataLimiteTeste.dayOfMonth).padStart(2, '0') +'/'+ String(contaObj.dataLimiteTeste.monthValue).padStart(2, '0') +'/'+ contaObj.dataLimiteTeste.year),
					'id': response.data.id, 
					'idConta': contaObj.id, 
					'cpf': response.data.cpf, 
					'rg': response.data.rg, 
					'dataNascimento': response.data.dataNascimento == null ? null : String(response.data.dataNascimento.dayOfMonth).padStart(2, '0') +'/'+ String(response.data.dataNascimento.monthValue).padStart(2, '0') +'/'+ response.data.dataNascimento.year, 
					'email': response.data.email, 
					'nomeCompleto': response.data.nomeCompleto, 
					'senha': response.data.senha, 
					'codigoSupervisor': response.data.codigoSupervisor, 
					'endereco': response.data.endereco, 
					'telefone': response.data.telefone
				}
			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		},
		buscarContas : function () {
			this.$store.dispatch('alternarTelaCarregamento', true)
			Object.assign(this.$data.pesquisa.retorno, this.$options.data.apply(this).pesquisa.retorno)

			this.$axios({
				method: 'get',
				url: this.urlRest +'configuracoesAdmin/searchContas',
				params: {
					valor: this.pesquisa.valor
				}
			}).then(response => {
				this.pesquisa.retorno = response.data

			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				} else {
					this.$toast.fire({
						icon: 'error',
						title: error
					});
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		},
		buscarCEP : function () {
         if (String(this.modal.endereco.cep).trim().length < 8) {
            return;
         }

         this.$store.dispatch('buscarCEP', this.modal.endereco.cep).then((data) => {
				this.modal.endereco.rua = data.logradouro
				this.modal.endereco.complemento = data.complemento
				this.modal.endereco.bairro = data.bairro
				this.modal.endereco.cidade = data.localidade
				this.modal.endereco.estado = data.uf
				this.modal.endereco.codMunicipio = data.ibge
			})
      },
		salvarConta : function () {
			if (this.modal.cpf == null || String(this.modal.cpf).trim().length == 0 || this.modal.nomeCompleto == null || 
				String(this.modal.nomeCompleto).trim().length == 0 || this.modal.email == null || String(this.modal.email).trim().length == 0 ||
				this.modal.tipoEmpresa == null || this.modal.qtdLojas == null || String(this.modal.qtdLojas).trim().length == 0 || this.modal.qtdUsuarios == null || 
				String(this.modal.qtdUsuarios).trim().length == 0 || this.modal.qtdPdvsOnline == null || String(this.modal.qtdPdvsOnline).trim().length == 0 || 
				this.modal.qtdPdvsOffline == null || String(this.modal.qtdPdvsOffline).trim().length == 0 || this.modal.credito == null || 
				String(this.modal.credito).trim().length == 0 || this.modal.diasVencimento == null || String(this.modal.diasVencimento).trim().length == 0 || 
				this.modal.valorFatura == null || String(this.modal.valorFatura).trim().length == 0) {

				this.$toast.fire({
					icon: 'error',
					title: 'Preencha todos os campos obrigatórios'
				});

				return
			}

			this.$store.dispatch('alternarTelaCarregamento', true)

			this.$axios({
				method: 'post',
				url: this.urlRest +'configuracoesAdmin/saveContaUsuario',
				headers: {'Content-Type': 'application/json'},
				data: this.modal

			}).then((response) => {
				$('#modalConta').modal('hide')

				if (this.modal.id == null) {
					router.push('/info/'+ this.tabs.length +'?idConta='+ response.data)
				}
				
				this.$toast.fire({
					icon: 'success',
					title: 'Conta salva!'
				});
			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else if (error.response.status == 400) {
						this.$toast.fire({
							icon: 'error',
							title: 'Email/CPF já cadastrado!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		}
	},
	mounted() {
		Object.assign(this.$data, this.tabs[this.$route.params.idTab].data)
	}
}

</script>